@import '../../styles/propertySets.css';

.pageWrapper {
  margin-left: 2rem;
  margin-top: 10rem;
}
.error {
  color: var(--failColor);
}
.loading {
  display: flex;
  justify-content: center;
}
